import { colors, sizes } from "../../../styles/clickable";

interface ColorLinkInterface
    extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    color: keyof typeof colors;
    size?: keyof typeof sizes;
}

export default function ColorLink({
    href,
    color,
    size = "md",
    className,
    children,
}: ColorLinkInterface) {
    return (
        <a
            href={href}
            className={`inline-flex items-center rounded-md border font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                colors[color]
            } ${sizes[size]} ${className ?? ""}`}
        >
            {children}
        </a>
    );
}
